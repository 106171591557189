.app-header {
  color: white;
  padding: 1rem;
  text-align: left;
  height: 10svh;
}
.app-logo svg {
  height: 25px;
  margin: 5px 0px;
}
.app-texts .primary-text {
  font-family: var(--primary-font);
  letter-spacing: var(--primary-letter-spacing);
  font-size: 0.8rem;
  text-align: left;
  margin: 5px 0px;
}
.app-texts .secondary-text {
  font-family: var(--primary-font);
  letter-spacing: var(--primary-letter-spacing);
  color: var(--primary-color);
  font-size: 0.8rem;
  text-align: left;
  margin: 0px;
}

@media screen and (height < 750px) {
  .app-texts .secondary-text {
    display: none;
  }
}
