.progress-container {
  text-align: center;
}
.progress-indicator {
  width: 45vw;
  height: 45vw;
}

@media screen and (height < 500px) {
  .progress-indicator {
    width: 35vw;
    height: 35vw;
  }
}

@media screen and (height < 450px) {
  .progress-indicator {
    width: 25vw;
    height: 25vw;
  }
}
