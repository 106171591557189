.footer {
  background-color: #040404;
  color: #aaa;
  text-align: end;
}

.footer-container {
  display: table;
  height: 100%;
  width: 100%;
}

.footer-content {
  display: table-cell;
  vertical-align: middle;
}

.footer-link {
  font-family: var(--primary-font);
  font-size: 1rem;
  color: #aaa;
  text-decoration: none;
  padding: 0 0.5rem;
}

.footer-link:hover {
  text-decoration: underline;
}

.separator {
  color: #555;
  padding: 0 0.5rem;
}
