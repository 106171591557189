/* Document Layout */
html,
body {
  background-color: var(--primary-background);
  margin: 0;
  padding: 0;
}

/* Content Layout */
.navbar {
  top: 0;
  width: 100%;
  height: 55px;
  min-height: 55px;
  position: fixed;
  z-index: 5;
}
.footer {
  bottom: 0px;
  width: 100vw;
  height: 40px;
  min-height: 40px;
  position: fixed;
  z-index: 5;
}

.content {
  position: absolute;
  top: 55px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  overflow-y: hidden;
  height: calc(100svh - 95px);
}
.overlay {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100svh - 95px);
  z-index: 3;
}
/* Text Layout*/
.small-heading {
  font-size: 1.2rem;
}

/* Global Styles */
:root {
  --primary-background: #040404;
  --primary-color: #ec6408;
  --primary-accent-color: #aaa;

  --success-color: #44c300;
  --warning-color: #e36400;
  --info-color: #ffc107;
  --danger-color: #d92323;

  --secondary-background: rgba(40, 40, 40, 0.8);

  /* Fonts */
  --primary-font: "helvetica_neue_lt_std35_thin";
  --primary-font-color: #ec6408;
  --primary-font-accent-color: #ec6408;
  --primary-letter-spacing: 0.275vmin;

  --secondary-font: "lexiaregular";
  --secondary-letter-spacing: 0.2vmin;
  --secondary-font-color: #fff;
  --secondary-font-accent-color: #aaa;
}
