.product-parameter-list {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0.25rem;
}
ul {
  margin: 0;
  padding: 0;
}

@media screen and (height < 650px) {
  .product-parameter-list {
    overflow-y: scroll;
  }
}
