.product-parameter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-background);
  border-radius: 10px;
  padding: 0.6rem;
  color: white;
  margin: 5px;
}

.product-info {
  display: flex;
  flex-direction: column;
}

.product-heading {
  font-family: var(--secondary-font);
  letter-spacing: var(--secondary-letter-spacing);
  font-size: 0.8rem;
  margin-bottom: 3px;
  color: var(--secondary-font-accent-color);
}

.product-heading.loading {
  border-radius: 2px;
  color: transparent;
  background-color: rgba(100, 100, 100, 50);
  width: 200px;
}

.product-name-details {
  display: flex;
  align-items: center;
}

.product-name.loading {
  border-radius: 2px;
  background-color: rgba(100, 100, 100, 50);
  width: 200px;
  color: transparent;
}

.product-name {
  font-family: var(--primary-font);
  letter-spacing: var(--primary-letter-spacing);
  font-size: 1rem;
  color: var(--secondary-font-color);
}

.product-details {
  font-family: var(--primary-font);
  letter-spacing: var(--primary-letter-spacing);
  font-size: 0.8rem;
  color: var(--secondary-font-accent-color);
}

.product-check {
  display: flex;
  align-items: center;
}
