.app-message-details {
  text-align: center;
  position: relative;
  top: calc(45px + 10svh);
  margin: 0 5vw;
}
.app-message-details p {
  font-family: var(--primary-font);
  letter-spacing: var(--primary-letter-spacing);
  font-size: 1rem;
  color: var(--secondary-font-color);
}

@media screen and (height < 575px) {
  .app-message-details {
    text-align: center;
    position: relative;
    top: calc(45px + 10svh);
    margin: 0 5vw;
  }
  .app-message-details p {
    font-family: var(--primary-font);
    letter-spacing: var(--primary-letter-spacing);
    font-size: 0.8rem;
    color: var(--secondary-font-color);
  }
}
