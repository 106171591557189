.app-message {
  position: relative;
  top: calc(45px + 10svh);
}

.message-heading {
  font-family: var(--primary-font);
  letter-spacing: var(--primary-letter-spacing);
  font-size: 1.8rem;
  text-align: center;
  margin: 1vw;
}

.message-content {
  font-family: var(--primary-font);
  letter-spacing: var(--primary-letter-spacing);
  font-size: 1.2rem;
  text-align: center;
  margin: 5vw 12vw;
}

.primary {
  color: var(--primary-font-color);
}

.secondary {
  color: var(--secondary-font-color);
}

.success {
  color: var(--success-color);
}

.interrupted {
  color: var(--warning-color);
}
.failed {
  color: var(--danger-color);
}
.info {
  color: var(--info-color);
}
@media screen and (height < 750px) {
  .message-heading {
    font-family: var(--primary-font);
    letter-spacing: var(--primary-letter-spacing);
    font-size: 1.2rem;
    text-align: center;
    margin: 1.5vw;
  }
  .message-content {
    font-family: var(--primary-font);
    letter-spacing: var(--primary-letter-spacing);
    font-size: 0.8rem;
    text-align: center;
    margin: 5vw 12vw;
  }
}

@media screen and (height < 725px) {
  .message-heading {
    font-family: var(--primary-font);
    letter-spacing: var(--primary-letter-spacing);
    font-size: 1.5rem;
    text-align: center;
    margin: 0;
  }
  .message-content {
    display: none;
  }
}

@media screen and (height < 600px) {
  .message-heading {
    font-family: var(--primary-font);
    letter-spacing: var(--primary-letter-spacing);
    font-size: 1rem;
    text-align: center;
    margin: 0;
  }
}

@media screen and (height < 550px) {
  .message-heading {
    display: none !important;
  }
}
