.navbar {
  background-color: #040404;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid var(--primary-color);
}
.navbar-logo img {
  height: 20px;
  margin: 0 30px;
}
